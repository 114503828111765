/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import s from "./style.module.css";
// import selectedSiteImg from "../../../assets/careerPathways/selectedSite.svg";
import {
  MenuItem,
  SubMenu,
  ControlledMenu,
  useClick,
} from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/index.css";
// import "./multileveldropdown.css";
// import data from "./data.json";
import RoleChart from "./RoleChart";
import { RiArrowDropDownLine } from "react-icons/ri";
import { RiArrowDropUpLine } from "react-icons/ri";
import axios from "axios";
import { baseURL } from "../../../utilities/getFromApi";
import Loader from "../../../utilities/Loader/Loader";
import ErrorPopup from "../../../utilities/ErrorPopup/ErrorPopup";
import AspiredRoleChart from "./AspiredRoleChart";
import {
  updateCurrentRoleList,
  updateCurrentSelectedFunction,
  updateCurrentRoleSelected,
  updateAspiredRoleSelected,
} from "../../../store/actions";
import DefaultView from "./DefaultView";

const SiteSelected = ({ selectedActiveSite, handleRolePage }) => {
  const ref = useRef(null);
  const storeData = useSelector((state) => state);
  const dispatch = useDispatch();
  const [isOpen, setOpen] = useState(false);
  const anchorProps = useClick(isOpen, setOpen);
  const [chartData, setChartData] = useState(null);
  const [customRole, setCustomRole] = useState(
    storeData.storeUserCurrentRoleSelected
  );
  const [aspiredRole, setAspiredRole] = useState(
    storeData.storeUserAspiredRoleSelected
  );
  const [noProg, setNoProg] = useState(false);
  const aspireRef = useRef(null);
  const [isAspireOpen, setAspireOpen] = useState(false);
  const aspireAnchorProps = useClick(isAspireOpen, setAspireOpen);
  const [error, setError] = useState(null);
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState([]);
  const [rolePaths, setRolePaths] = useState(null);

  useEffect(() => {
    const { storeUserCurrentRoleSelected } = storeData;
    if (!storeUserCurrentRoleSelected) {
      setLoader(true);
      // Resetting custom & aspired roles values when active site selection is changed
      setCustomRole(null);
      setAspiredRole(null);

      // Append "_" for replacing spaces in selectedActiveSite param
      const selectedSite = selectedActiveSite.value.replace(/\s/g, "_");
      axios
        .post(
          `${baseURL}api/get_role_names`,
          {
            selected_site: selectedSite,
          },
          {
            headers: {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
            },
          }
        )
        .then((response) => {
          if (response && response.data) {
            dispatch(updateCurrentRoleList(response.data));
            const arr = [{ value: "Select" }];
            setData(arr.concat(response.data));
            setLoader(false);
          }
        })
        .catch((error) => {
          setError(error);
          setLoader(false);
        });
    }
  }, [selectedActiveSite]);

  const handleRoleChange = (role) => {
    setNoProg(false);
    setLoader(true);
    setCustomRole(role);
    setChartData(null);
    setRolePaths(null);
    dispatch(updateCurrentRoleSelected(role));
    dispatch(updateCurrentSelectedFunction(null));
    setLoader(false);
  };

  const handleAspiredRoleChange = (role) => {
    setAspiredRole(role);
    dispatch(updateAspiredRoleSelected(role));
  };
  useEffect(() => {
    setCustomRole(storeData.storeUserCurrentRoleSelected);
    setAspiredRole(storeData.storeUserAspiredRoleSelected);
    if (storeData.storeUserCurrentRoleSelected) {
      const arr = [{
        children: [],
        label: "Select",
        value: "Select"
      }];
      setData(arr.concat(storeData.storeCurrentRoleList));
    }
  }, []);

  const getRoleInfoApiData = (customRole) => {
    if (customRole.value !== "Select") {
      setLoader(true);
      axios
        .post(
          `${baseURL}api/get_role_all_possibilities_info`,
          {
            site_name:
              selectedActiveSite.value === "Grange Castle"
                ? "Grange_Castle"
                : selectedActiveSite.value,
            current_role: customRole.value,
          },
          {
            headers: {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
            },
          }
        )
        .then((response) => {
          if (response && response.data) {
            const apiData = response.data;
            // const filteredResponse = apiData.filter(
            //   (resp) => resp.departments.length !== 0 && resp.gmsgq === null
            // );
            setChartData(apiData);
            setLoader(false);
          }
        })
        .catch((error) => {
          setError(error);
          setLoader(false);
        });
    }
  };
  const getRoleProgApiData = (aspiredRole) => {
    if (
      aspiredRole &&
      customRole &&
      customRole.value !== "Select" &&
      aspiredRole.value !== "Select"
    ) {
      setLoader(true);
      axios
        .post(
          `${baseURL}api/get_role_progressions`,
          {
            site_name: selectedActiveSite.value.replace(/\s/g, "_"),
            current_role: customRole.value,
            aspired_role: aspiredRole.value,
          },
          {
            headers: {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
            },
          }
        )
        .then((response) => {
          const apiData = response.data;
          if (apiData.career_pathway_flag) {
            setRolePaths(response.data);
            setChartData(null);
            setLoader(false);
            setNoProg(false);
          } else {
            setRolePaths(null);
            setNoProg(true);
            getRoleInfoApiData(customRole);
            setLoader(false);
          }
        })
        .catch((error) => {
          setError(error);
          setLoader(false);
        });
    }
  };
  // useEffect(() => {
  //   if (customRole) {
  //     getRoleInfoApiData(customRole);
  //   }
  // }, [customRole]);

  // useEffect(() => {
  //   if (aspiredRole) {
  //     getRoleProgApiData(aspiredRole);
  //   }
  // }, [aspiredRole]);

  useEffect(() => {
    if (customRole) {
      if (aspiredRole && aspiredRole.value !== "Select") {
        getRoleProgApiData(aspiredRole);
      } else {
        getRoleInfoApiData(customRole);
      }
    }
  }, [customRole, aspiredRole]);

  return (
    <div>
      <Loader state={loader} />
      <div>
        <div className={s.roleDropdown}>
          <div className={s.dropdownContainer}>
            {/* -------------------------------------- Current Role -------------------------------------- */}
            <p className={s.label}>Current Role</p>

            <div className={s.customSelect} onClick={() => setOpen(true)}>
              <button
                type="button"
                ref={ref}
                {...anchorProps}
                className={s.menuButton}
                onClick={() => {
                  setOpen(true);
                }}
              >
                <p className={s.selectText}>
                  {customRole === null ? "Select" : customRole.value}
                </p>
              </button>
              {/* -------------------------------------- Custom Role Arrow Logic -------------------------------------- */}
              {isOpen ? (
                <div className={`${s.arrowContainer} ${s.arrowUpContainer}`}>
                  <RiArrowDropUpLine className={s.arrowIcon} />
                </div>
              ) : (
                <div className={s.arrowContainer}>
                  <RiArrowDropDownLine className={s.arrowIcon} />
                </div>
              )}
            </div>

            {/* -------------------------------------- Custom Role MultiLevel Menus -------------------------------------- */}
            <ControlledMenu
              state={isOpen ? "open" : "closed"}
              anchorRef={ref}
              onClose={() => {
                setOpen(false);
              }}
              overflowY="scroll"
            >
              {data &&
                data.map((list, index) => {
                  return (
                    <div key={index} className={s.subSelection}>
                      {list.children && list.children.length > 0 ? (
                        <SubMenu label={list.value}>
                          {list.children.map((role, ind) => {
                            return (
                              <div className={s.subSelection}>
                                {role.children && role.children.length > 0 ? (
                                  <SubMenu
                                    label={role.value}
                                    menuStyle={{
                                      maxHeight: "250px",
                                      overflowY: "auto",
                                    }}
                                  >
                                    {role.children.map((fun, i) => {
                                      return (
                                        <MenuItem
                                          className={s.menuHover}
                                          onClick={() => {
                                            // setCustomRole(fun);
                                            handleRoleChange(fun);
                                            setAspiredRole(null);
                                          }}
                                        >
                                          {fun.value}
                                        </MenuItem>
                                      );
                                    })}
                                  </SubMenu>
                                ) : (
                                  <MenuItem
                                    className={s.menuHover}
                                    onClick={() => {
                                      handleRoleChange(role);
                                      // setCustomRole(role);
                                      setAspiredRole(null);
                                    }}
                                  >
                                    {role.value}
                                  </MenuItem>
                                )}
                              </div>
                            );
                          })}
                        </SubMenu>
                      ) : (
                        <MenuItem
                          className={s.menuHover}
                          onClick={() => {
                            handleRoleChange(list);
                            // setCustomRole(list);
                            setAspiredRole(null);
                          }}
                        >
                          {list.value}
                        </MenuItem>
                      )}
                    </div>
                  );
                })}
            </ControlledMenu>
          </div>

          {/* -------------------------------------- Aspired Role -------------------------------------- */}
          <div className={s.dropdownContainer}>
            <p
              className={
                customRole !== null && customRole.value !== "Select"
                  ? s.label
                  : s.inactiveLabel
              }
            >
              Aspired Role
            </p>

            <div
              className={
                customRole !== null && customRole.value !== "Select"
                  ? s.customSelect
                  : s.disabledCustomSelect
              }
              onClick={() =>
                customRole &&
                customRole.value &&
                customRole.value !== "Select" &&
                setAspireOpen(true)
              }
            >
              <button
                type="button"
                ref={aspireRef}
                {...aspireAnchorProps}
                className={
                  customRole !== null && customRole.value !== "Select"
                    ? s.menuButton
                    : s.menuButtonDisabled
                }
                onClick={() =>
                  customRole &&
                  customRole.value &&
                  customRole.value !== "Select" &&
                  setAspireOpen(true)
                }
              >
                <p className={s.selectText}>
                  {aspiredRole === null ? "Select" : aspiredRole.value}
                </p>
              </button>

              {/* -------------------------------------- Aspired Role Arrow Logic -------------------------------------- */}
              {customRole === null ||
                (customRole !== null && customRole.value === "Select") ? (
                <div className={s.disabledArrowContainer}>
                  <RiArrowDropDownLine className={s.disabledArrowIcon} />
                </div>
              ) : isAspireOpen ? (
                <div className={`${s.arrowContainer} ${s.arrowUpContainer}`}>
                  <RiArrowDropUpLine className={s.arrowIcon} />
                </div>
              ) : (
                <div className={s.arrowContainer}>
                  <RiArrowDropDownLine className={s.arrowIcon} />
                </div>
              )}
            </div>

            {/* -------------------------------------- Aspired Role MultiLevel Menus -------------------------------------- */}
            <ControlledMenu
              state={isAspireOpen ? "open" : "closed"}
              anchorRef={aspireRef}
              onClose={() => {
                setAspireOpen(false);
              }}
              overflowY="scroll"
            >
              {data &&
                data.map((list, index) => {
                  return (
                    <div key={index} className={s.subSelection}>
                      {list.children && list.children.length > 0 ? (
                        <SubMenu label={list.value}>
                          {list.children.map((role, ind) => {
                            return (
                              <div className={s.subSelection}>
                                {role.children && role.children.length > 0 ? (
                                  <SubMenu
                                    label={role.value}
                                    menuStyle={{
                                      maxHeight: "250px",
                                      overflowY: "auto",
                                    }}
                                  >
                                    {role.children.map((fun, i) => {
                                      return (
                                        <MenuItem
                                          className={s.menuHover}
                                          onClick={() => {
                                            handleAspiredRoleChange(fun);
                                          }}
                                        >
                                          {fun.value}
                                        </MenuItem>
                                      );
                                    })}
                                  </SubMenu>
                                ) : (
                                  <MenuItem
                                    className={s.menuHover}
                                    onClick={() => {
                                      handleAspiredRoleChange(role);
                                    }}
                                  >
                                    {role.value}
                                  </MenuItem>
                                )}
                              </div>
                            );
                          })}
                        </SubMenu>
                      ) : (
                        <MenuItem
                          className={s.menuHover}
                          onClick={() => {
                            handleAspiredRoleChange(list);
                          }}
                        >
                          {list.value}
                        </MenuItem>
                      )}
                    </div>
                  );
                })}
            </ControlledMenu>
          </div>
        </div>
      </div>
      {customRole &&
        customRole.value &&
        Array.isArray(chartData) &&
        chartData.length > 0 ? (
        <RoleChart
          chartData={chartData}
          customRole={customRole.value}
          aspiredRole={
            aspiredRole && aspiredRole.value ? aspiredRole.value : ""
          }
          handleRolePage={handleRolePage}
          noProg={noProg}
        />
      ) : (
        aspiredRole === null && <DefaultView />
      )}

      {error !== null && (
        <ErrorPopup error={error} closeBtn={() => setError(null)} />
      )}

      {aspiredRole &&
        aspiredRole.value &&
        aspiredRole.value !== "Select" &&
        rolePaths && (
          <AspiredRoleChart
            customRole={customRole.value}
            aspiredRole={aspiredRole.value}
            handleRolePage={handleRolePage}
            // selectedSite={selectedActiveSite.value}
            rolePaths={rolePaths}
          />
        )}
    </div>
  );
};

export default SiteSelected;
